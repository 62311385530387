<script setup>
import { onMounted, ref } from "vue";
import { store } from "@/store";
import { useRoute } from "vue-router";
import useApi from "@/composables/useApi";
import ResultsMultiple from "@/views/Guest/QuickPayment/ResultsMultiple.vue";
const { service, invoice } = useApi();

const loading = ref(false);
const id = ref(0);
const route = useRoute();
const serviceData = ref({});
const invoicesToPayData = ref([]);
const hasError = ref(false);

const serviceNumber = ref("");

const loadService = async (service_id) => {
  const data = await service.getServiceById(service_id);
  return data.payload[0];
};

const loadInvoices = async (service_id) => {
  const data = await invoice.listInvoicesByService(service_id);
  let result = data.payload.filter(invoice => ["not_paid", "partial"].includes(invoice.payment_state));
  result.sort((a, b) => {
    // First sort by due date descending
    const dateCompare = new Date(b.invoice_date_due) - new Date(a.invoice_date_due);
    if (dateCompare !== 0) return dateCompare;
    // If dates are equal, sort by id ascending
    return a.id - b.id;
  });
  result = result.map(invoice => ({
    ...invoice,
    state_to_pay: true
  }));
  return result;
};

onMounted(async () => {
  store.APP_BACK_BUTTON = true;
  console.log("onMounted [QuickPayment]");
  try {
    id.value = route.params.id;
    store.APP_LOADING = true;
    loading.value = true;
    serviceData.value = await loadService(id.value);
    serviceNumber.value = serviceData.value.name;
    invoicesToPayData.value = await loadInvoices(id.value);
  } catch (error) {
    console.log(error);
  } finally {
    store.APP_LOADING = false;
    loading.value = false;
  }
});
</script>
<template lang="pug">
div
  //- pre {{ serviceData }}
  //- pre {{ invoicesToPayData }}
  ResultsMultiple(:key="0" :serviceNumber="serviceNumber" :hasError="hasError" v-model:invoices="invoicesToPayData" :service="serviceData" :showOption="false")
</template>
